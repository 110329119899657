<template>
    <modal ref="modalSetFechaImplementacion" titulo="Día(s) deshabilitado" aceptar @aceptar="guardarValores">
        <ValidationObserver ref="formCompleto" tag="div">
            <div class="row justify-content-center mx-0 py-3">
                <p class="col-10 f-15 mb-3">Selecciona los días que estará inactiva la zona</p>
                <ValidationProvider v-slot="{errors}" tag="div" class="col-10 mb-3" rules="required|max:750" name="motivo">
                    <p class="f-12 pl-2 label-inputs text-left">Motivo</p>
                    <el-input v-model="dia.motivo" placeholder="Motivo" size="small" class="w-100" />
                    <span class="text-danger f-10">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" tag="div" rules="required" class="col-10 mb-3" name="fecha inicio">
                    <p class="f-12 pl-2 label-inputs text-left">Día de inicio</p>
                    <el-date-picker v-model="dia.fecha_inicio" align="center" type="date" placeholder="Seleccionar" size="small" class="w-100" />
                    <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" tag="div" rules="required" class="col-10 mb-3" name="fecha fin">
                    <p class="f-12 pl-2 label-inputs text-left">Día de finalización</p>
                    <el-date-picker v-model="dia.fecha_fin" align="center" type="date" placeholder="Seleccionar" size="small" class="w-100" />
                    <p class="text-danger f-10">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            dia: {
                id: null,
                motivo: '',
                fecha_inicio: null,
                fecha_fin: null
            }
        }
    },
    methods:{
        toggle(){          
            this.$refs.modalSetFechaImplementacion.toggle()
        },
        async guardarValores(){
            let validate = await this.$refs.formCompleto.validate()
            if(!validate) return this.notificacion('','Campos incompletos','warning')

            this.toggle()
            this.$emit('guardarDia',this.dia)
        },
        reset(){
            this.dia = {
                id: null,
                motivo: '',
                fecha_inicio: '',
                fecha_fin: ''
            }
        },
        setDia(dia){
            this.dia = dia
        }
    }
}
</script>
<style lang="scss" scoped>

</style>